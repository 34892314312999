<template>
  <div class="event-stream-block">
    <div class="event-detail__timer mb-0">
      <div class="event-detail__timer-title">
        {{
          !Object.keys(timerResult).length
            ? "Трансляция началась"
            : timerResult.months
            ? "Подключение к трансляции:"
            : "Трансляция начнется через:"
        }}
      </div>
      <div class="event-detail__timer-date mt-sm-4" v-if="timerResult.months">
        {{ $m(event.date).format("DD MMMM") }}
        в
        {{
          $m
            .utc(event.date)
            .utcOffset(+3)
            .subtract(10, "minutes")
            .format("HH:mm")
        }}
        (МСК)
      </div>
      <div
        v-else-if="Object.keys(timerResult).length"
        class="event-detail__timer-row"
      >
        <div class="event-detail__timer-item" v-if="timerResult.months">
          <div class="timer__value">{{ timerResult.months }}</div>
          <div class="timer__text">Месяцев</div>
        </div>
        <div class="event-detail__timer-item" v-if="timerResult.days > 0">
          <div class="timer__value">{{ timerResult.days }}</div>
          <div class="timer__text">
            {{ helpers.getNoun(timerResult.days, "день", "дня", "дней") }}
          </div>
        </div>
        <div class="event-detail__timer-item" v-if="timerResult.hours > 0">
          <div class="timer__value">{{ timerResult.hours }}</div>
          <div class="timer__text">
            {{ helpers.getNoun(timerResult.hours, "час", "часа", "часов") }}
          </div>
        </div>
        <div class="event-detail__timer-item" v-if="timerResult.minutes > 0">
          <div class="timer__value">{{ timerResult.minutes }}</div>
          <div class="timer__text">
            {{ helpers.getNoun(timerResult.minutes, "минуту", "минуты", "минут") }}
          </div>
        </div>
        <div class="event-detail__timer-item" v-if="timerResult.seconds > 0">
          <div class="timer__value">{{ timerResult.seconds }}</div>
          <div class="timer__text">
            {{ helpers.getNoun(timerResult.seconds, "секунду", "секунды", "секунд") }}
          </div>
        </div>
      </div>
      <div
        v-if="!Object.keys(timerResult).length"
        @click="openIframe()"
        class="event-detail__translation button button_white-blue px-6"
      >
        Смотреть трансляцию
      </div>
    </div>
    <div
      class="event-detail__iframe mb-8"
      v-if="
        ((IframeAccess &&
          Object.keys(IframeAccess).length &&
          IframeAccess[`event_${event.event_id}`] &&
          Object.keys(IframeAccess[`event_${event.event_id}`]).length) ||
          event.webinar_id) &&
        !Object.keys(timerResult).length &&
        openFrame
      "
      :class="{ 'event-detail__iframe_opened': openFrame }"
    >
      <div
        @click="openFrame = false"
        v-if="openFrame"
        class="event-detail__close-btn"
      >
        <span class="button button_pink button_small">Назад</span>
        <!-- <svg
                  class="d-xl-none"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.25 5.25L18.75 18.75"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  ></path>
                  <path
                    d="M18.75 5.25L5.25 18.75"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  ></path>
                </svg> -->
      </div>
      <iframe
        v-if="
          IframeAccess &&
          IframeAccess[`event_${event.event_id}`] &&
          Object.keys(IframeAccess[`event_${event.event_id}`]).length
        "
        class="d-xl-block"
        id="userplayer"
        frameborder="0"
        :src="
          IframeAccess[`event_${event.event_id}`].url +
          '&lang=Ru-ru&disableSupport=1'
        "
        allow="camera;microphone;fullscreen;accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture;display-capture;"
        allowusermedia
      ></iframe>
      <iframe
        v-else
        class="d-xl-block"
        id="userplayer"
        frameborder="0"
        :src="`https://edu.livedigital.space/room/${
          event.webinar_id
        }/?participantName=${
          $route.query.email || ''
        }&lang=Ru-ru&disableSupport=1`"
        allow="camera;microphone;fullscreen;accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture;display-capture;"
        allowusermedia
      ></iframe>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "EventStreamBlock",
  props: {
    event: Object,
  },
  data: () => ({
    timerResult: { id: 0 },
    openFrame: false,
    interval: null,
  }),
  computed: {
    ...mapGetters(["IframeAccess"]),
  },
  methods: {
    ...mapActions(["fetchIframeAccess"]),
    timer() {
      let date = new Date();
      const dateEnd = this.$m
        .utc(this.event.date)
        .utcOffset(+3)
        .subtract(10, "minutes");
      const today = this.$m.utc(date).utcOffset(+3);
      const diff = today.diff(dateEnd, "seconds");
      this.$set(this, "timerResult", {});
      if (diff > 0) return;
      let months = Math.abs(diff) / 2592000;
      let fullMonths = Math.floor(months);
      let days = (months - Math.floor(months)) * 30;
      let fullDays = Math.floor(days);
      let hours = (days - Math.floor(days)) * 24;
      let fullHours = Math.floor(hours);
      let minutes = (hours - Math.floor(hours)) * 60;
      let fullMinutes = Math.floor(minutes);
      let seconds = (minutes - Math.floor(minutes)) * 60;
      let fullSeconds = Math.round(seconds);
      this.$set(this, "timerResult", {});
      if (fullMonths) {
        this.$set(this.timerResult, "months", fullMonths);
      }
      if (fullDays) {
        this.$set(this.timerResult, "days", fullDays);
      }
      if (fullHours) {
        this.$set(this.timerResult, "hours", fullHours);
      }
      if (fullMinutes) {
        this.$set(this.timerResult, "minutes", fullMinutes);
      }
      if (fullSeconds) {
        this.$set(this.timerResult, "seconds", fullSeconds);
      }
    },
   
    openIframe() {
      if (!localStorage.getItem("token") && !!!Object.keys(this.user).length) {
        if (!!!this.$route.query.access) {
          this.popup = true;
        } else {
          this.popup = true;
        }
      } else {
        this.openFrame = true;
      }
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "forsiga", {
          forsiga: {
            "watch broadcast": {
              ...this.$root.ymFields,
            },
          },
        });
      }
    },
  },
  mounted() {
    this.timer();
    this.interval = setInterval(this.timer, 1000);
  },
  watch: {
    async timerResult() {
      if (!Object.keys(this.timerResult).length) {
        this.fetchIframeAccess(this.event.event_id);
        clearInterval(this.interval);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.event-detail {
  &__timer {
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 37px;
    border-radius: 16px;
    background: #2186af;
    // background-image: url('https://storage.yandexcloud.net/'+$VUE_APP_BACKET_NAME+'/media/content/public/assets/img/galaxy/timer.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
    position: relative;

    // &::before,
    // &::after {
    //   width: 100%;
    //   height: 22px;
    //   position: absolute;
    //   left: 0;
    //   background-repeat: no-repeat;
    //   background-size: 100%;
    //   background-color: #f3fdff;
    //   display: block;
    //   content: "";
    // }

    // &::before {
    //   top: 0;
    //   background-image: url('https://storage.yandexcloud.net/'+$VUE_APP_BACKET_NAME+'/media/content/public/assets/img/galaxy/timer_t.png');
    //   background-position: bottom left;
    // }

    //  &::after {
    //   bottom: 0;
    //   background-image: url('https://storage.yandexcloud.net/'+$VUE_APP_BACKET_NAME+'/media/content/public/assets/img/galaxy/timer_b.png');
    //   background-position: top left;
    // }

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      // align-items: flex-start;
    }

    @media screen and (max-width: 767px) {
      padding: 16px;
    }

    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      margin-right: auto;

      @media screen and (max-width: 1220px) {
        margin-right: 0;
        margin-bottom: 32px;
      }

      @media screen and (max-width: 767px) {
        font-size: 21px;
        line-height: 26px;
      }
    }

    &-item {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 40px;
      &:last-child {
        margin-right: 0;
        &::after {
          display: none;
        }
      }

      &::after {
        display: block;
        content: "";
        width: 1px;
        height: 50%;
        background-color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }

      @media screen and (max-width: 1220px) {
        padding: 0 15px;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        padding: 0 15px;
      }
    }

    &-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (max-width: 1220px) {
        // width: 100%;
      }
    }

    &-date {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 42px;
      line-height: 48px;
      color: #ffffff;

      @media screen and (max-width: 767px) {
        font-size: 26px;
      }
    }
  }

  &__iframe {
    position: relative;
    width: 100%;
    display: none;

    & iframe {
      width: 100%;
      height: 800px;

      @media screen and (max-width: 1220px) {
        width: 100%;
        height: 100%;
      }
    }

    @media screen and (max-width: 1220px) {
      display: none;
    }
  }

  &__iframe_opened {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    padding: 64px;

    & iframe {
      height: 100%;
    }

    @media screen and (max-width: 1220px) {
      padding: 0;
    }
  }

  &__close-btn {
    cursor: pointer;
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 2;

    @media screen and (max-width: 1220px) {
      top: 0;
      right: 50%;
      transform: translateX(50%);
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__translation {
    max-width: 280px;
    // width: 189px;

    @media screen and (max-width: 1220px) {
      width: auto;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: none;
    }
  }

  &__date-info {
    border-left: 4px solid #0194ac;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px;
    margin-top: 64px;
    margin-bottom: 40px;
    background-color: #f8f8f8;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      margin-top: 40px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 32px;
    }
  }

  &__about-title {
    margin-bottom: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #3c4242;
  }

  &__about-description {
    margin-bottom: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #656969;
  }

  &__date-text {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
    }
  }

  &__date-item {
    margin-right: 32px;

    @media screen and (max-width: 1220px) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  &__date-item-title {
    margin-bottom: 4px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b2b4b4;
  }

  &__date-item-value {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #3c4242;
  }
}

.timer {
  &__value {
    margin-right: 16px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;

    @media screen and (max-width: 767px) {
      margin-right: 0;
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
</style>